import PrimeVue from "primevue/config";
import ProgressSpinner from "primevue/progressspinner";
import PrimeButton from "primevue/button";
import Card from "primevue/card";
import Divider from "primevue/divider";
import Chart from "primevue/chart";
import SplitButton from "primevue/splitbutton";

import { createApp } from "vue";
import { i18n } from "./locales/i18n";

export default function configureApp(app: ReturnType<typeof createApp>) {
  app.use(PrimeVue);
  app.use(i18n);

  app.component("ProgressSpinner", ProgressSpinner);
  app.component("PrimeButton", PrimeButton);
  app.component("Card", Card);
  app.component("Divider", Divider);
  app.component("Chart", Chart);
  app.component("SplitButton", SplitButton);
}
