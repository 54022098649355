<template>
  <Chart type="bar" :data="basicData" :options="basicOptions" />
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</template>

<script setup lang="ts">
import { ref } from "vue";

const basicData = ref({
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "This year",
      backgroundColor: "#59B5E8",
      data: [65, 59, 80, 81, 56, 55, 40],
    },
    {
      label: "Last year",
      backgroundColor: "#33B580",
      data: [28, 48, 40, 19, 86, 27, 90],
    },
  ],
});

const basicOptions = ref({
  plugins: {
    legend: {
      labels: {
        color: "#495057",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
    y: {
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(canvas) {
  max-width: 100%;
}
</style>
