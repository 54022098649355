<template>
  <div class="c-warns flex c-card c-card-shadow m-0 md:m-1">
    <div>
      <div class="flex justify-content-between flex-wrap card-container green-container mb-3">
        <div class="flex align-items-center justify-content-center font-bold">Varsel</div>
        <div class="flex align-items-center justify-content-center">
          <div class="c-warns-counter">5</div>
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="c-warn-card c-card c-alert-success-dark m-1">
          <div class="c-warn-card-header">Ikke avsluttet ordre</div>
          <div class="c-warn-card-kunde">Ukjent kunde</div>

          <div class="c-warn-card-extended">
            <div class="c-warn-card-ordre">Ordrenummer: 00125487</div>
            <PrimeButton label="Se ordre" class="p-button-success mt-2" @click="noAction"> </PrimeButton>
          </div>
        </div>

        <div class="c-warn-card c-card c-alert-success-light m-1">
          <div class="c-warn-card-header">Ikke avsluttet ordre</div>
          <div class="c-warn-card-kunde">Ukjent kunde</div>

          <div class="c-warn-card-extended hidden">
            <div class="c-warn-card-ordre">Ordrenummer: 00125487</div>
            <PrimeButton label="Se ordre" class="p-button-success mt-2" @click="noAction"> </PrimeButton>
          </div>
        </div>

        <div class="c-warn-card c-card c-alert-warning m-1">
          <div class="c-warn-card-header">Ikke avsluttet ordre</div>
          <div class="c-warn-card-kunde">Ukjent kunde</div>

          <div class="c-warn-card-extended hidden">
            <div class="c-warn-card-ordre">Ordrenummer: 00125487</div>
            <PrimeButton label="Se ordre" class="p-button-success mt-2" @click="noAction"> </PrimeButton>
          </div>
        </div>

        <div class="c-warn-card c-card c-alert-warning m-1">
          <div class="c-warn-card-header">Ikke avsluttet ordre</div>
          <div class="c-warn-card-kunde">Ukjent kunde</div>

          <div class="c-warn-card-extended hidden">
            <div class="c-warn-card-ordre">Ordrenummer: 00125487</div>
            <PrimeButton label="Se ordre" class="p-button-success mt-2" @click="noAction"> </PrimeButton>
          </div>
        </div>

        <div class="c-warn-card c-card c-alert-error m-1">
          <div class="c-warn-card-header">Ikke avsluttet ordre</div>
          <div class="c-warn-card-kunde">Ukjent kunde</div>

          <div class="c-warn-card-extended hidden">
            <div class="c-warn-card-ordre">Ordrenummer: 00125487</div>
            <PrimeButton label="Se ordre" class="p-button-success mt-2" @click="noAction"> </PrimeButton>
          </div>
        </div>

        <div class="c-warn-card c-card c-alert-error m-1">
          <div class="c-warn-card-header">Ikke avsluttet ordre</div>
          <div class="c-warn-card-kunde">Ukjent kunde</div>

          <div class="c-warn-card-extended hidden">
            <div class="c-warn-card-ordre">Ordrenummer: 00125487</div>
            <PrimeButton label="Se ordre" class="p-button-success mt-2" @click="noAction"> </PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const noAction = () => {
  alert("Under construction");
};
</script>

<style lang="scss" scoped>
.c-warns {
  font-family: var(--font-family);
  border-radius: 0;
  @media (min-width: 992px) {
    max-width: 21.2rem;
  }

  @media (min-width: 687px) {
    border-radius: var(--card-border-radius);
  }
}

.c-warns-counter {
  background: var(--c-yellow-100);
  width: 1.3rem;
  height: 1.3rem;
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  font-size: 0.7rem;
  font-weight: 700;
}

.c-warn-card {
  border: none;
  font-size: 14px;
  min-width: 100%;
}
.c-warn-card-header {
  font-weight: bold;
}
.c-warn-card-ordre {
  font-size: 14px;
}

.c-alert-success-light {
  background: var(--alert-success-light-bg);
}
.c-alert-success-dark {
  background: var(--alert-success-dark-bg);
}
.c-alert-warning {
  background: var(--alert-warning-bg);
}
.c-alert-error {
  background: var(--alert-error-bg);
}
</style>
