<template>
  <div class="c-footer">
    <div class="flex flex-wrap my-2">
      <div class="c-footer-tip flex flex-grow-1 m-1">
        <div>
          <PrimeButton :label="t('dashboard.tips-from')" class="p-button-text text-gray-900 text-lg" />
        </div>
      </div>

      <div class="c-footer-col flex flex-grow-1">
        <div>
          <PrimeButton class="p-button-text underline font-medium pl-0">Slik oppretter du ny kunde</PrimeButton>
          <div class="c-footer-col-text mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictum elementum eget facilisi urna.
          </div>
        </div>
      </div>

      <div class="c-footer-col flex flex-grow-1">
        <div>
          <PrimeButton class="p-button-text underline font-medium pl-0">Lage ordre</PrimeButton>
          <div class="c-footer-col-text mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
        </div>
      </div>

      <div class="c-footer-col flex flex-grow-1">
        <div>
          <PrimeButton class="p-button-text underline font-medium pl-0">Salgsstatistikk</PrimeButton>
          <div class="c-footer-col-text mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
        </div>
      </div>

      <div class="c-footer-col flex flex-grow-1">
        <div>
          <PrimeButton class="p-button-text underline font-medium pl-0">Massesletting av produkter</PrimeButton>
          <div class="c-footer-col-text mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.c-footer {
  position: relative;
  width: 100%;
  margin-top: 0.4rem;
  padding: 0.5rem 1rem;
  justify-content: left;
  border-radius: 0;
  @media (min-width: 687px) {
    border-radius: 12px;
    z-index: 0;
  }
}

.c-footer-tip {
  width: 100%;
  font-weight: 500;

  @media (min-width: 992px) {
    max-width: 12rem;
    min-width: 12rem;
  }
  @media (min-width: 1230px) {
    max-width: 18.5rem;
    min-width: 18.5rem;
  }
}
.c-footer-col {
  width: 50%;
  padding: 0 1.5rem;
  &:last-child {
    border-right: none;
  }

  @media (min-width: 992px) {
    margin: 0.5rem;
    border-right: var(--footer-border);
    max-width: 10rem;
    min-width: 10rem;
  }
  @media (min-width: 1230px) {
    max-width: 12rem;
    min-width: 12rem;
  }
  @media (min-width: 1500px) {
    max-width: 16rem;
    min-width: 16rem;
  }
  @media (min-width: 1780px) {
    max-width: 21.2rem;
    min-width: 21.2rem;
  }

  .p-button-text {
    color: var(--c-blue-500);
  }
  .c-footer-col-text {
    font-family: var(--font-family);
    font-size: 14px;
  }
}
</style>
