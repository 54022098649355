<template>
  <div class="flex flex-wrap mt-6 md:mt-2 lg:mt-3 mb-2">
    <PrimeButton class="c-dashboard-link c-card c-card-shadow c-quicklink-order" @click="route('/order/add')">
      <div class="c-dashboard-content">
        <div class="c-dashboard-icon">
          <i class="pi pi-shopping-cart" />
        </div>
        <div class="c-dashboard-text">
          {{ t("dashboard.add-order") }}
        </div>
      </div>
      <div @click="onClickArrow($event, '/order/add')" class="c-quicklink-arrow-icon">
        <i class="pi pi-chevron-right" />
      </div>
    </PrimeButton>

    <PrimeButton class="c-dashboard-link c-card c-card-shadow c-quicklink-customer" @click="route('/customer/add')">
      <div class="c-dashboard-content">
        <div class="c-dashboard-icon">
          <i class="pi pi-user" />
        </div>
        <div class="c-dashboard-text">
          {{ t("dashboard.add-customer") }}
        </div>
      </div>
      <div @click="onClickArrow($event, '/customer/add')" class="c-quicklink-arrow-icon">
        <i class="pi pi-chevron-right" />
      </div>
    </PrimeButton>

    <PrimeButton class="c-dashboard-link c-card c-card-shadow c-quicklink-product" @click="route('/product/add')">
      <div class="c-dashboard-content">
        <div class="c-dashboard-icon">
          <i class="pi pi-clone" />
        </div>
        <div class="c-dashboard-text">
          {{ t("dashboard.add-product") }}
        </div>
      </div>
      <div @click="onClickArrow($event, '/product/add')" class="c-quicklink-arrow-icon">
        <i class="pi pi-chevron-right" />
      </div>
    </PrimeButton>

    <PrimeButton
      class="c-dashboard-link c-card c-card-shadow c-quicklink-receipt"
      @click="route('/goods-reception/search')"
    >
      <div class="c-dashboard-content">
        <div class="c-dashboard-icon">
          <i class="pi pi-sign-in" />
        </div>
        <div class="c-dashboard-text">
          {{ t("dashboard.goods-receipt") }}
        </div>
      </div>
      <div @click="onClickArrow($event, '/goods-reception/search')" class="c-quicklink-arrow-icon">
        <i class="pi pi-chevron-right" />
      </div>
    </PrimeButton>

    <PrimeButton
      class="c-dashboard-link c-card c-card-shadow c-quicklink-inventory"
      @click="route(inventoryRoute)"
      data-testid="inventory-button"
    >
      <div class="c-dashboard-content">
        <div class="c-dashboard-icon">
          <i class="pi pi-box" />
        </div>
        <div class="c-dashboard-text">
          {{ t("dashboard.inventory") }}
        </div>
      </div>
      <div @click="onClickArrow($event, inventoryRoute)" class="c-quicklink-arrow-icon" data-testid="inventory-arrow">
        <i class="pi pi-chevron-right" />
      </div>
    </PrimeButton>
  </div>
</template>

<script setup lang="ts">
import router from "@/router/router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const inventoryRoute = "/warehouse/inventory/search";
const route = (path: string) => {
  router.push(path);
};
const onClickArrow = (event: Event, path: string) => {
  event.stopPropagation();
  window.open(path, "_blank");
};
</script>

<style lang="scss" scoped>
.c-dashboard-link {
  margin: 0.25rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  border-color: transparent;
  border-radius: 0;

  @media (min-width: 687px) {
    border-radius: var(--card-border-radius);
  }

  &:first-child {
    @media (min-width: 992px) {
      min-width: 21.199rem;
      max-width: 21.2rem;
    }
  }
  &:last-child {
    @media (min-width: 922px) and (max-width: 992px) {
      gap: 12rem;
    }
  }

  &:hover,
  &:active {
    background: var(--quicklink-hover-icon);
  }

  .c-quicklink-arrow-icon {
    border-radius: 50%;
    padding: 0.3rem;
  }
  .c-quicklink-arrow-icon:hover {
    background: var(--quicklink-hover);
    color: var(--quicklink-hover-icon);
  }
}

.c-quicklink-order {
  background: var(--quicklink-order-bg);
  color: var(--quicklink-order-icon);

  &:hover,
  &:active {
    color: var(--quicklink-order-icon);
    border-color: var(--quicklink-order-icon);
  }
}
.c-quicklink-customer {
  background: var(--quicklink-customer-bg);
  color: var(--quicklink-customer-icon);

  &:hover,
  &:active {
    color: var(--quicklink-customer-icon);
    border-color: var(--quicklink-customer-icon);
  }
}
.c-quicklink-product {
  background: var(--quicklink-product-bg);
  color: var(--quicklink-product-icon);

  &:hover,
  &:active {
    color: var(--quicklink-product-icon);
    border-color: var(--quicklink-product-icon);
  }
}
.c-quicklink-receipt {
  background: var(--quicklink-receipt-bg);
  color: var(--quicklink-receipt-icon);

  &:hover,
  &:active {
    color: var(--quicklink-receipt-icon);
    border-color: var(--quicklink-receipt-icon);
  }
}
.c-quicklink-inventory {
  background: var(--quicklink-inventory-bg);
  color: var(--quicklink-inventory-icon);

  &:hover,
  &:active {
    color: var(--quicklink-inventory-icon);
    border-color: var(--quicklink-inventory-icon);
  }
}
.c-dashboard-link:hover .c-quicklink-order,
.c-dashboard-link:hover .c-quicklink-customer,
.c-dashboard-link:hover .c-quicklink-product,
.c-dashboard-link:hover .c-quicklink-receipt,
.c-dashboard-link:hover .c-quicklink-inventory {
  background: var(--quicklink-hover);
  color: var(--quicklink-hover-icon);
}
.c-dashboard-content {
  display: flex;
  align-items: center;
}
.c-dashboard-icon {
  font-size: 2.5rem;
  margin: 0.25rem;
  padding: 1rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  .pi {
    font-size: 2.5rem;
  }
}

.c-dashboard-text {
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 500;
  min-width: 12rem;
  text-align: left;

  @media (min-width: 992px) {
    min-width: auto;
  }
}
.c-dashboard-link-active {
  &:hover,
  &:active {
    background: linear-gradient(220.64deg, #c8f5ff 0%, #a2daff 100%);
  }
}
</style>
